@import "Colors.scss";
@import "Text.scss";

.container-1 {
  min-height: 60vmin;
}

.loading-container {
  min-height: 60vmin;
  background-color: $color-background-white;
}

.product-details-image {
  max-width: auto;
  max-height: calc(100px + 30vmin);
  padding: 30px;
}

.store-buttons-container {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
}
