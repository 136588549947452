@import "Colors.scss";

.cart-container {
  background-color: $color-background-white;
  padding: 24px;
}

.cart-left-container {
  background-color: $color-background-gray;
  padding: 40px;
  min-width: fit-content;
}

.cart-right-container {
  padding: 24px calc(20px + 2vmin) 40px;
}

.cart-img {
  max-height: 600px;
  min-width: 200px;
  object-fit: scale-down;
}