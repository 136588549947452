@import "Colors.scss";
@import "Text.scss";

.ack-container {
  background-color: $color-background-white;
  min-height: 800px;
}

.left-container {
  background-color: $color-background-gray;
  padding: 40px;
}

.ak-section-container {
  padding: 24px calc(20px + 2vmin) 40px;
  background-color: $color-background-white;
}

.ak-img {
  object-fit: scale-down;
  min-width: 200px;
}