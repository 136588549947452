@import "./Colors.scss";

.centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shrink-section {
  max-width: 800px;
  min-width: 300px;
}

.shrink-image {
  max-width: auto;
  max-height: calc(100px + 30vmin);
}

.custom-border-1 {
  border-width: 10px;
  border-color: red;
}

.leash {
  background-color: $color-primary;
  height: 12px;
}

.title-image {
  max-height: auto;
  max-width: calc(40px + 20vmin);
}

.background-dark-diluted {
  background-image: linear-gradient(to right, $color-background-dark, $color-background-dark-2);
}