@import "Colors.scss";

.underC-container {
  background-color: $color-background-white;
}

.warning-container {
  background-color: $color-warning;
  padding: 40px;
}

.uc-section-container {
  padding: 24px calc(20px + 2vmin) 40px;
}

.uc-img {
  max-height: 600px;
  object-fit: scale-down;
  min-width: 200px;
}