@import "../scss/Colors.scss";

.carousel-inner {
  img{
    max-height: 800px;
    object-fit: scale-down;
    background-color: $color-background-dark;
  }
}

.carousel {
  background-color: $color-background-dark;
}