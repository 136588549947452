@import "Colors.scss";
@import "Text.scss";

.out-of-stock-container {
  background-color: $color-error-background;
  padding: 8px;
  margin: 8px 8px 8px 0px;
  border-radius: 10px;
  width: fit-content;
}

.out-of-stock-text {
  color: $color-error-dark;
  font-weight: 500;
}
