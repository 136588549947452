@import "Colors.scss";
@import "Common.scss";

.container-loading {
  background-color: $color-background-dark;
  width: 100%;
  height: 100%;
  min-height: 500px;
};

.image-loading {
  max-width: auto;
  max-height: calc(10px + 10vmin);
};