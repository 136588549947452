$color-primary: #FC427B;
$color-secondary: #a72b51;
$color-third: #651b31;
$color-forth: #fd729c;
$color-primary-light: #fcacc4;

// Also used for #lavender
$color-lavender-primary: #B563BC;
$color-lavender-secondary: #EABCFB;
$color-lavender-third: #806B98;
$color-lavender-forth: #5f1166;

//Also used for #tea-tree
$color-tea-primary: #0f4a39;
$color-tea-secondary: #14634c;
$color-tea-third: #20a37e;
$color-tea-forth: #0a3126;

//Also used for #peppermint
$color-peppermint-primary: #26896d;
$color-peppermint-secondary: #26e7b0;
$color-peppermint-third: #73ddbf;
$color-peppermint-forth: #0c4536;

//Also used for #rose
$color-rose-primary: #90173d;
$color-rose-secondary: #df5e87;
$color-rose-third: #bc7a8f;
$color-rose-forth: #450c1e;

$color-background-dark:  #00190D;
$color-background-dark-2:  #181717;
$color-logo-dark:  #00190D;
$color-text-gray:  #e0dddd;
$color-text-white:  #fffdfd;
$color-view-title: $color-primary;
$color-background-light: #e1d5e1;
$color-background-white: #fffdfd;
$color-background-gray: #e0dbdb;
$color-background-gray-2: #9a929a;

$color-disabled-gray: #c7c3c3;
$color-title-gray: #b5b3b3;

$color-red:  #f95a42;
$color-error:  #f06b56;
$color-error-dark:  #ab2c19;
$color-error-background:  #f7d9d4;
$color-blue:  #459dd4;
$color-white:  #fff;
$color-warning:  #e7b52a;


:export {
  color-primary: $color-primary;
  colorSecondary:$color-secondary;
  colorThird:$color-third;
  colorForth:$color-forth;
  colorPrimaryLight:$color-primary-light;

  lavenderPrimary: $color-lavender-primary;
  lavenderSecondary: $color-lavender-secondary;
  lavenderThird: $color-lavender-third;
  lavenderForth: $color-lavender-forth;

  teaPrimary: $color-tea-primary;
  teaSecondary: $color-tea-secondary;
  teaThird: $color-tea-third;
  teaForth: $color-tea-forth;

  peppermintPrimary: $color-peppermint-primary;
  peppermintSecondary: $color-peppermint-secondary;
  peppermintThird: $color-peppermint-third;
  peppermintForth: $color-peppermint-forth;

  rosePrimary: $color-rose-primary;
  roseSecondary: $color-rose-secondary;
  roseThird: $color-rose-third;
  roseForth: $color-rose-forth;

  backgroundDark: $color-background-dark;
  logoDark: $color-logo-dark;
  textGray: $color-text-gray;
  textWhite: $color-text-white;
  viewTitle: $color-view-title;
  
  backgroundLight: $color-background-light;
  backgroundWhite: $color-background-white;
  backgroundGray: $color-background-gray;
  backgroundGray2: $color-background-gray-2;

  section: $color-title-gray;

  disabled: $color-disabled-gray;

  red: $color-red;
  error: $color-error;
  errorDark: $color-error-dark;
  errorBackground: $color-error-background;
  blue: $color-blue;
  white: $color-white;
  warning: $color-warning;
}