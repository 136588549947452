@import "Colors.scss";

.btn-normal {
  padding: 12px;
  margin: 8px 16px 8px 0px;
  border-radius: 12px;
  border-width: 0px;
}

.btn-bigger {
  padding: 12px;
  margin: 8px 16px 8px 0px;
  border-radius: 12px;
  border-width: 0px;
}

.btn-success {
  padding: 12px;
  margin: 8px 16px 8px 0px;
  border-radius: 12px;
  border-width: 0px;
}