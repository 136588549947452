
.amazon-button-image {
  height: 40px
}

.walmart-button-container {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 8px;
}

.walmart-button-image {
  border-radius: 0px;
  height: 50px;
}
