@import "Colors.scss";
@import "Text.scss";

.footer {
  background-color: $color-background-dark;
  color: $color-text-gray;
}

.footer-text {
  color: $color-text-gray;
  @extend .font-min-size
}

.footer-icon {
  color: $color-text-gray;
}

.left-icon {
  height: calc(30px + 5vmin);
  width: auto;
  min-height: 120px;
}

.flag-icon {
  height: auto;
  width: calc(15px + 5vmin);
}