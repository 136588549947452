@import "Colors.scss";
@import "Common.scss";

.home-container {
  background-image: linear-gradient(to right, $color-background-dark,  $color-lavender-forth)
};

.home-footer-img {
  background-color: $color-background-light;
  object-fit: scale-down;
};

.home-main-img {
  object-fit: scale-down;
};

.home-img {
  object-fit: scale-down;
  margin-top: 20px
};

.home-container-list {
  margin: 16px;
}