@import "Colors.scss";

.material-safety-container {
  min-height: 400px;
}

.material-safety-title-container {
  background-color: $color-title-gray;
  display: flex;
  justify-content: center;
}

.material-safety-title {
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
}

.material-safety-section {
  display: flex;
  margin: 8px 0px;
}

.material-safety-section-title-container {
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: flex-start;
  margin-left: 12px;
}

.material-safety-section-title {
  font-size: 16px;
  font-weight: 500;
  padding: 4px;
}

.material-safety-section-content-container {
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  margin-right: 12px;
}

.material-safety-section-content-text {
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
}

.material-safety-section-image {
  width:  30vmin;
  max-width: 150px;
  height: auto;
  margin: 4px 8px 4px 0px;
}