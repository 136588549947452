.display-line-break {
  white-space: pre-line;
}

.view-title {
  color: $color-view-title;
  font-size: 4vmin;
  padding: 8px;
}

.font-min-size {
  font-size: calc(7px + 1vmin);
}

.text-container {
  padding: 8px;
}

.text {
  font-size: calc(10px + .5vmin);
}

.text-light {
  font-size: calc(10px + .5vmin);
  color: color-text-white;
}

.text-small {
  font-size: calc(8px + .5vmin);
}

.text-title-light {
  font-size: calc(14px + .5vmin);
  color: $color-forth;
  font-weight: 600;
}

.text-title {
  font-size: calc(14px + .5vmin);
  color: $color-primary;
  font-weight: 600;
}

.text-title-black-xl {
  font-size: calc(22px + .5vmin);
  color: $color-logo-dark;
  font-weight: 700;
}

.text-title-black-1 {
  font-size: calc(18px + .5vmin);
  color: $color-logo-dark;
  font-weight: 600;
}

.text-title-black-2 {
  font-size: calc(14px + .5vmin);
  color: $color-logo-dark;
  font-weight: 600;
}

.text-title-white {
  font-size: calc(14px + .5vmin);
  color: $color-white;
  font-weight: 600;
}

.text-small-white {
  font-size: calc(8px + .5vmin);
  color: $color-white;
}

.text-error {
  font-size: calc(5px + .5vmin);
  color: $color-error;
  font-weight: 600;
}

.text-strike {
  font-size: calc(14px + .5vmin);
  color: $color-disabled-gray;
  font-weight: 600;
  text-decoration: line-through;
}