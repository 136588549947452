@import "Colors.scss";
@import "Text.scss";

.pre-title {
  font-weight: 700;
}

.pre-container {
  background-color: $color-background-dark;
}

.pre-left-container {
  background-color: $color-background-white;
  padding: 40px;
}

.pre-section-container {
  padding: 24px calc(20px + 2vmin) 40px;
  background-color: $color-background-light;
}

.pre-img {
  max-height: 600px;
  object-fit: scale-down;
  min-width: 300px;
}