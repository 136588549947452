@import "Colors.scss";
@import "Text.scss";
@import "Common.scss";

.help-container {
  background-color: $color-background-white;
  min-height: 500px;
  height: fit-content;
}

.help-autocomplete {
  color: aqua;
  font-size: 30px;
}
