@import "Colors.scss";
@import "Text.scss";

.checkout-form {
  text-align: justify;
}

.checkout-title {
  font-weight: 700;
}

.checkout-container {
  background-color: $color-background-white;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  
}

.checkout-left-container {
  background-color: $color-background-white;
  padding: 40px;
}

.checkout-section-container {
  padding: 24px calc(20px + 2vmin) 40px;
  background-color: $color-background-light;
}

.checkout-img {
  max-height: 400px;
  object-fit: scale-down;
  min-width: 100px;
}

.checkout-text-area {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  min-width: 200px;
  @extend .font-min-size;
}

.checkout-text-container {
  width: 400px;
}

.checkout-input {
  @extend .font-min-size;
  width: 200px;
  margin-left: 8px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

}


.FormGroup {
	// margin: 0 15px 20px;
	// padding: 0;
  min-width: 350px;
	border-style: none;
	background-color: $color-primary-light;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
  flex-grow: inherit;
}

.FormRow {
	// display: -ms-flexbox;
	display: flex;
	// -ms-flex-align: c/enter;
	align-items: center;
	margin-left: 15px;
  // width: 300px;
	border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}