@import "Colors.scss";

.nav-bar-text {
  color: $color-text-white;
  margin: 10px;
  font-size: 15px;
  -webkit-text-decoration: none;
  text-decoration: none;
  vertical-align: top;
  font-weight: 800;
}