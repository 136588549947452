@import "./Colors.scss";

.image-list {
  width: calc(10px + 10vmin);
  height: auto;
  min-width: 100px;
}

.item-container-list {
  margin: 20px !important;
  min-width: 300px;
  border-radius: 10px;
  background-color: $color-background-white;
  text-decoration: none;
  color: $color-logo-dark;
}

.item-sub-container-list {
  padding: 16px;
}
