@import "Colors.scss";

.fav-container {
  background-color: $color-background-white;
}

.fav-left-container {
  background-color: $color-background-gray;
  padding: 40px;
  min-width: fit-content;
}

.fav-right-container {
  padding: 24px calc(20px + 2vmin) 40px;
}

.fav-img {
  max-height: 600px;
  min-width: 200px;
  object-fit: scale-down;
}