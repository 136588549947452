@import "Colors.scss";

.benefits-container {
  padding: 16px;
}

.sub-container {
  padding: 18px ;
  background-color: $color-background-white;
  border-radius: 12px;
}

