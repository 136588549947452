@import "Colors.scss";
@import "Text.scss";

.contact-form {
  text-align: justify;
}

.contact-title {
  font-weight: 700;
}

.contact-container {
  background-color: $color-background-dark;
}

.contact-left-container {
  background-color: $color-background-white;
  padding: 40px;
}

.contact-section-container {
  padding: 24px calc(20px + 2vmin) 40px;
  background-color: $color-background-light;
}

.contact-img {
  max-height: 400px;
  object-fit: scale-down;
  min-width: 100px;
}

.contact-text-area {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  min-width: 200px;
  @extend .font-min-size;
}

.contact-text-container {
  width: 400px;
}

.contact-input {
  @extend .font-min-size;
  width: 200px;
  margin-left: 8px;
}