@import "Colors.scss";

.header-background {
  background-color: $color-background-dark;
}

.header-container {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 2fr;
}

.header-logo {
  height: calc(30px + 5vmin);
  width: auto;
  min-height: 50px;
}

.top-header-link {
  color: $color-text-gray;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}

.logo-top-container {
  padding: 12px;
  grid-column-start: 1;
  grid-column-end: 2;
}

.icon-cart-text-container {
  padding: 12px;
  grid-column-start: 2;
  grid-column-end: 3;
}

.icon-cart-text{
  @media screen and (max-width: 400px) {
    display: none;
  }
}

